import Flicking from "@egjs/flicking";
import { AutoPlay } from "@egjs/flicking-plugins";

import React from "react";

const Carousel = ({ items }) => {
  const [carousel, setCarousel] = React.useState();
  const container = React.useRef(null);

  const handleClick = (step) => () => {
    step === "next" ? carousel.next() : carousel.prev();
  };
  React.useEffect(() => {
    container.current &&
      setCarousel(
        new Flicking(container.current, {
          circular: true,
          hanger: 0,
          anchor: 0,
          gap: 20,
          duration: 500,
          autoResize: true,
        })
      );
  }, []);

  React.useLayoutEffect(() => {
    carousel &&
      carousel.addPlugins(
        new AutoPlay({ duration: 3000, stopOnHover: true }, "NEXT")
      );
  }, [carousel]);

  return (
    <>
      <div
        className="flick-carousel"
        ref={container}
        style={{ minWidth: "255px" }}
      >
        {items.map((item) => item)}
      </div>
      <div className="flick-nav">
        <div
          role="button"
          onClick={handleClick("prev")}
          className="flick-prev"
          tabIndex={-1}
        >
          <i className="bx bx-chevron-left"></i>
        </div>
        <div
          role="button"
          onClick={handleClick("next")}
          className="flick-next"
          tabIndex={-2}
        >
          <i className="bx bx-chevron-right"></i>
        </div>
      </div>
    </>
  );
};

export default Carousel;
