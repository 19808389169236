const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => (m && m.default) || m


exports.components = {
  "component---cache-dev-404-page-js": hot(preferDefault(require("/app/.cache/dev-404-page.js"))),
  "component---src-pages-about-us-js": hot(preferDefault(require("/app/src/pages/about-us.js"))),
  "component---src-pages-blog-details-js": hot(preferDefault(require("/app/src/pages/blog-details.js"))),
  "component---src-pages-blog-js": hot(preferDefault(require("/app/src/pages/blog.js"))),
  "component---src-pages-case-studies-details-js": hot(preferDefault(require("/app/src/pages/case-studies-details.js"))),
  "component---src-pages-case-studies-js": hot(preferDefault(require("/app/src/pages/case-studies.js"))),
  "component---src-pages-coming-soon-js": hot(preferDefault(require("/app/src/pages/coming-soon.js"))),
  "component---src-pages-contact-js": hot(preferDefault(require("/app/src/pages/contact.js"))),
  "component---src-pages-course-details-js": hot(preferDefault(require("/app/src/pages/course-details.js"))),
  "component---src-pages-courses-js": hot(preferDefault(require("/app/src/pages/courses.js"))),
  "component---src-pages-event-details-js": hot(preferDefault(require("/app/src/pages/event-details.js"))),
  "component---src-pages-events-js": hot(preferDefault(require("/app/src/pages/events.js"))),
  "component---src-pages-faq-js": hot(preferDefault(require("/app/src/pages/faq.js"))),
  "component---src-pages-gallery-js": hot(preferDefault(require("/app/src/pages/gallery.js"))),
  "component---src-pages-history-js": hot(preferDefault(require("/app/src/pages/history.js"))),
  "component---src-pages-index-js": hot(preferDefault(require("/app/src/pages/index.js"))),
  "component---src-pages-membership-levels-js": hot(preferDefault(require("/app/src/pages/membership-levels.js"))),
  "component---src-pages-privacy-policy-js": hot(preferDefault(require("/app/src/pages/privacy-policy.js"))),
  "component---src-pages-profile-authentication-js": hot(preferDefault(require("/app/src/pages/profile-authentication.js"))),
  "component---src-pages-service-details-js": hot(preferDefault(require("/app/src/pages/service-details.js"))),
  "component---src-pages-services-js": hot(preferDefault(require("/app/src/pages/services.js"))),
  "component---src-pages-team-js": hot(preferDefault(require("/app/src/pages/team.js"))),
  "component---src-pages-terms-of-service-js": hot(preferDefault(require("/app/src/pages/terms-of-service.js"))),
  "component---src-pages-testimonials-js": hot(preferDefault(require("/app/src/pages/testimonials.js")))
}

