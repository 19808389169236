import React from 'react'
import {Link} from 'gatsby'

const ServicesOne = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/services">
                                Startup Applications
                                </Link>
                            </h3>
                            <p>Planing, Estimation, Definition, Execution, Revision and Iteration.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/services">
                                Cloud Solutions
                                </Link>
                            </h3>

                            <p>AWS, AZURE, GCP.</p>                                                  
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/services">
                                eCommerce Platforms
                                </Link>
                            </h3>

                            <p>Shopify, Reaction Commerce, Magento, Stripe, Amazon Pay, Paypal....</p>
                            
                           
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/services">
                                Cloud Security
                                </Link>
                            </h3>

                            <p>PRTG, F-Secure.</p>
                                                       
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/services">
                                Research and Business Intelligence
                                </Link>
                            </h3>

                            <p>Coming soon.</p>
                                                       
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/services">
                                Artificial Intelligence
                                </Link>
                            </h3>

                            <p>Comming soon.</p>
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/services">
                                Technology
                                </Link>
                            </h3>

                            <p>Javascript, React, Nodejs, Angular, Java, .Net, NOSQL, SQL, Docker, MongoDB, AWS...</p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesOne