import React from "react";
import { Link } from "gatsby";
import starIcon from "../../assets/images/star-icon.png";
import client1 from "../../assets/images/testimonials/client1.jpg";
import shape from "../../assets/images/shape/shape1.svg";
import Carousel from "../../utils/carousel";

const Testimonials = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  const items = [];

  for (let index = 0; index < 4; index++) {
    let el = (
      <div
        key={index}
        className="single-testimonials-item"
        style={{
          width: "525px",
        }}
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum
          suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
          lacus vel facilisis.
        </p>

        <div className="client-info">
          <div className="d-flex justify-content-center align-items-center">
            <img src={client1} alt="about" />
            <div className="title">
              <h3>Alex Maxwell {index}</h3>
              <span>CEO at EnvyTheme</span>
            </div>
          </div>
        </div>
      </div>
    );
    items.push(el);
  }
  return (
    <section className="testimonials-area bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="about" />
            Testimonials
          </span>
          <h2>What Our Clients are Saying?</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
        </div>
        {display ? <Carousel items={items}></Carousel> : null}
        <div className="testimonials-view-btn text-center">
          <Link to="/testimonials" className="default-btn">
            <i className="flaticon-view"></i>
            Check Out All Reviews <span></span>
          </Link>
        </div>
      </div>

      <div className="shape-img1">
        <img src={shape} alt="about" />
      </div>
    </section>
  );
};

export default Testimonials;
