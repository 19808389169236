import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import team2 from '../../assets/images/team/team2.jpg'
import team3 from '../../assets/images/team/team4.jpg'
import alberto from '../../assets/images/team/Alberto.jpg'
import raidel from '../../assets/images/team/Raidel.jpg'
import eduardo from '../../assets/images/team/Eduardo.jpeg'
import aluisco from '../../assets/images/team/Aluisco.jpg'
import diego from '../../assets/images/team/Diego.jpg'
import armando from '../../assets/images/team/Armando.jpg'
import jorge from '../../assets/images/team/Jorge.jpg'
import merlys from '../../assets/images/team/Merlys.jpg'
import bismay from '../../assets/images/team/Bismay.jpg'
import daniel from '../../assets/images/team/Daniel.jpg'

const TeamMember = () => {
    return (
        <section className="scientist-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="team" /> 
                        Team Members
                    </span>
                    <h2>Main Team</h2>
                    <p>Make use of our cultivated team of highly skilled individuals, experts who have been honing their craft for an average of 10+ years, gathered over the past 15+ years.</p>
                </div>

                <div className="row">
                    

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <img src={raidel} alt="team" />

                                <ul className="social">
                                    
                                </ul>
                            </div>
                            <div className="content">
                                <h3>Raidel Blanco Brito</h3>
                                <span>Sys Ops and Cyber Security Lead</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <img src={jorge} alt="team" />

                                <ul className="social">
                                    
                                </ul>
                            </div>
                            <div className="content">
                                <h3>Jorge Recio Capote</h3>
                                <span>Full Stack Lead</span>
                            </div>
                        </div>
                    </div>
                                    

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <img src={bismay} alt="team" />

                                <ul className="social">
                                   
                                </ul>
                            </div>
                            <div className="content">
                                <h3>Bismay Morgado</h3>
                                <span>Full Stack Lead</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <img src={armando} alt="team" />

                                <ul className="social">
                                    
                                </ul>
                            </div>
                            <div className="content">
                                <h3>Armando Carracedo Velazquez</h3>
                                <span>Full Stack Lead</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <img src={daniel} alt="team" />

                                <ul className="social">
                                    
                                </ul>
                            </div>
                            <div className="content">
                                <h3>Daniel Sabedra Rodriguez</h3>
                                <span>Full Stack Lead</span>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <img src={aluisco} alt="team" />

                                <ul className="social">
                                    
                                </ul>
                            </div>
                            <div className="content">
                                <h3>Aluisco Miguel Ricardo Mastrapa</h3>
                                <span>DEVOPS and Sys Ops Lead</span>
                            </div>
                        </div>
                    </div>

                                    
                </div>
            </div>
        </section>
    )
}

export default TeamMember;