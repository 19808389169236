import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Services</h2>
                                    <p>Our services include free onboarding.</p>
                                    <ul className="about-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Amazon AWS Support
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Microsoft Azure Support
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Google Cloud Support
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Web, desktop and mobile Development 
                                        </li>
                                             
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Docker Support                    
                                        </li>                            
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Cyber Security 
                                        </li>  
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Management Website Hosting
                                        </li>                                         
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Servers Instalation and Maintenance
                                        </li>                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Design & Development Services</h2>
                                    <p>Serving Across the Entire IT Stack. Our services include free onboarding. </p>                        

                                    <ul className="our-mission-list mb-0">                                           
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            MongoBD, MSQL, MySQL, PostgreSQL, Firebase, Oracle, SQL, NoSQL 
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            React, Nodejs, Meteor, Vuejs, Gatsby, Nextjs, React Native, Angular, Electron
                                        </li>   
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            AWS, AZURE, GCP 
                                        </li>                                                                                                                                                         
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Apps, Web
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            PHP, Laravel
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            UX/UI Design, Invision, Figma, Responsive Design
                                        </li>                                                                          
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Java, Kotlin
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            .NET, Python
                                        </li>                                                                                                                            
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            CI/CD, Docker, Git
                                        </li>                                          
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Scrum, Kanban, Jira, Trello
                                        </li>  
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Artificial intelligence
                                        </li>       
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            E-commerce, Shopify, CRM, ERP
                                        </li>                                                                                                                          
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;