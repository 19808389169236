import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Our Solutions
                    </span>
                    <h2>Daily checking to reassure and obtain maximum customer satisfaction</h2>
                    <p>Our high level of expertise in hundreds of technologies will ensure quality outcome for your business.</p>
                    <p>No matter how simple or extremely complex is the project; we have around the world who will build it exactly what is needed.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Code Security
                                </Link>
                            </h3>
                            <p>You will gain the control and confidence you need to securely run your business with the most flexible and secure cloud computing environments available today like AWS, AZURE and GCP.</p>                           
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    JIRA / Trello
                                </Link>
                            </h3>
                            <p>We design and set up products that allows us to manage the work for a wide range of cases from requirements and test cases to agile software development.</p>                                                        
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    High quality code
                                </Link>
                            </h3> 
                            <p>85% of PRs are merged in the first review cycle.</p>                                                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;