import React from "react";
import { Link } from "gatsby";
import starIcon from "../../assets/images/star-icon.png";
import client1 from "../../assets/images/testimonials/client1.jpg";
import client2 from "../../assets/images/testimonials/client2.jpg";
import client3 from "../../assets/images/testimonials/client3.png";
import shape from "../../assets/images/shape/shape1.svg";
import Carousel from "../../utils/carousel";

const clients = {
  client1,
  client2,
  client3,
};

const items = [];

for (let index = 1; index < 4; index++) {
  let el = (
    <div key={index} className="single-testimonials-item">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse
        ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel
        facilisis.
      </p>

      <div className="client-info">
        <div className="d-flex justify-content-center align-items-center">
          <img src={clients[`client${index}`]} alt="about" />
          <div className="title">
            <h3>Alex Maxwell {index}</h3>
            <span>CEO at EnvyTheme</span>
          </div>
        </div>
      </div>
    </div>
  );
  items.push(el);
}

const Testimonials = () => {
  const [display, setDisplay] = React.useState(false);

  React.useEffect(() => {
    setDisplay(true);
  }, []);

  return (
    <div className="testimonials-area pt-100 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="testimonial" />
            Testimonials
          </span>
          <h2>What Our Clients are Saying?</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
        </div>

        {display ? <Carousel items={items} /> : ""}

        <div className="testimonials-view-btn text-center">
          <Link to="/testimonials" className="default-btn">
            <i className="flaticon-view"></i>
            Check Out All Reviews <span></span>
          </Link>
        </div>
      </div>

      <div className="shape-img1">
        <img src={shape} alt="testimonial" />
      </div>
    </div>
  );
};

export default Testimonials;
